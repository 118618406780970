import React from "react";
import VideoDolphin from "../asset/video.mp4"; // Import the video file
import VideoDolphin2 from "../asset/video2.mp4"; // Import the video file

export const Video = () => {
  return (
    <div className="py-10 w-full px-5">
      <h2 className="text-3xl font-extrabold text-gray-800 mb-8 text-center">
        Our Beautiful Activity
      </h2>
      <div className="flex  justify-center items-center gap-6 flex-wrap">
        <div>
          {" "}
          <video
            controls
            className="rounded-lg shadow-lg w-[400px] md:w-auto"
            style={{
              height: "auto", // Set to auto for responsiveness
            }}
          >
            <source src={VideoDolphin} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div>
          {/* Video 2 */}
          <video
            controls
            className="rounded-lg shadow-lg w-[400px] md:w-auto"
            style={{
              height: "auto", // Set to auto for responsiveness
            }}
          >
            <source src={VideoDolphin2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};
